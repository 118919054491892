import React from 'react';

import { Checkbox, Field, Label } from '@headlessui/react';

import CheckmarkIcon from '../Icons/CheckmarkIcon';
import ValidationError from '../ValidationError';

const DCheckbox = ({
  checked,
  onChange,
  label,
  disabled = false,
  hideError = false,
  className,
  size,
  error,
  ...props
}) => {
  let fontSize = 'text-base';
  if (size === 'sm') {
    fontSize = 'text-sm';
  }
  return (
    <div className="flex flex-col gap-size1 w-full" {...props}>
      <Field
        className={`flex items-center gap-2 ${className}`}
        disabled={disabled}
      >
        <Checkbox
          checked={checked}
          onChange={onChange}
          className={`group block size-5 flex items-center justify-center rounded border bg-white data-[checked]:bg-black data-[disabled]:opacity-50`}
          data-testid={`checkbox-${props.name ?? ''}`}
        >
          <CheckmarkIcon
            width="7"
            className="hidden size-4 text-white group-data-[checked]:block"
          />
        </Checkbox>
        <Label className={`text-sm data-[disabled]:opacity-50 ${fontSize}`}>
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </Label>
      </Field>
      {!hideError && <ValidationError error={error} />}
    </div>
  );
};

export default DCheckbox;
