export const Star = (props) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.99975 9.70354L3.08566 12.085L4.14993 7.64052L0.666748 4.66871L5.23884 4.30387L6.99975 0.0849609L8.76132 4.30387L13.3334 4.66871L9.85023 7.64052L10.9145 12.085L6.99975 9.70354Z"
        fill="#0AB679"
      />
    </svg>
  );
};

const Stars = ({ rating }) => {
  const stars = Array.from({ length: rating }, (_, index) => <Star key={index} />);
  return <div className="flex">{stars}</div>;
};

export default Stars;
