import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import BookIcon from '../Global/Icons/BookIcon';
import DiscordColorIcon from '../Global/Icons/DiscordColorIcon';
import EmailIcon from '../Global/Icons/EmailIcon';
import QuestionMarkIcon from '../Global/Icons/QuestionMarkIcon';

const HeaderLearningHub = () => {
  return (
    <Menu>
      {({ open }) => (
        <>
          {open && (
            <div className="transition-all fixed inset-0 bg-white/90 md:hidden z-20"></div>
          )}
          {open && (
            <div className="transition-all fixed inset-0 top-20 bg-white/90 md:hidden z-20"></div>
          )}
          <MenuButton className="inline-flex items-center justify-center gap-2 rounded-size0 z-30   font-semibold text-black  focus:outline-none data-[hover]:bg-grey-2 data-[open]:bg-grey-5 data-[focus]:outline-1 data-[focus]:outline-black size-[40px] inset-1">
            <QuestionMarkIcon  className="size-5" color={localStorage.getItem('theme') === 'dark' ? '#FFFFFF' : '#0f172a'}/>
          </MenuButton>
          <MenuItems
            transition
            anchor="bottom end"
            className="w-full md:w-64 z-20 origin-top-right p-size1 rounded-size0 border border-black/5 bg-white text-black transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <button className="dbutton w-full text-left flex items-center gap-size1 my-size1 text-sm hover:bg-purple-100" onClick={() => window.open('mailto:support@dante-ai.com', '_blank')}>
              <div className="flex items-center justify-center bg-purple-100 rounded-size0 text-purple-300 p-size1">
                <EmailIcon className="size-4" />
              </div>
              Contact our support
            </button>
            <button className="dbutton w-full text-left flex items-center gap-size1 my-size1 text-sm hover:bg-purple-100" onClick={() => window.open('https://www.dante-ai.com/guides', '_blank')}>
              <div className="flex items-center justify-center bg-purple-100 rounded-size0 text-purple-300 p-size1">
                <BookIcon className="size-4" />
              </div>
              Read our in depth guides
            </button>
            <button className="dbutton w-full text-left flex items-center gap-size1 my-size1 text-sm hover:bg-purple-100" onClick={() => window.open('https://discord.com/invite/sEe4yUY8BM', '_blank')}>
              <div className="flex items-center justify-center bg-purple-100 rounded-size0 text-purple-300 p-size1">
                <DiscordColorIcon className="size-4" />
              </div>
              Join our Discord community
            </button>
          </MenuItems>
        </>
      )}
    </Menu>
  );
};

export default HeaderLearningHub;
