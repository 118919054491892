/**
 * Updates the dataLayer with user information for analytics purposes
 *
 * @param {Object} user - User data object from the userStore
 * @param {Object} auth - Auth data object from the userStore
 */
export const updateUserDataLayer = (user, auth) => {
  if (typeof window === 'undefined') return;

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'user_details',
    'user_id': user?.id || auth?.user_id || '',
    'email': user?.email || auth?.email || '',
    'login_status': (!!auth?.access_token).toString(),
  });
};
