import { Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useUserStore } from '@/stores/user/userStore';
import useLogout from '@/application/auth/logout';

const PublicRoute = ({ permission, children }) => {
  const location = useLocation();
  const logout = useLogout();

  const { auth, user } = useUserStore();

  return auth.access_token && !location.pathname.includes('sign-up') && !user.should_redirect ? (
    <Navigate to="/" replace />
  ) : (
    <Suspense>{children}</Suspense>
  );
};

export default PublicRoute;
