import companies from '@/assets/others/trustpilot/companies-trust.png';
import trustpilotLogo from '@/assets/others/trustpilot/trustpilot-logo.svg';
import trustpilotStar from '@/assets/others/trustpilot/trustpilot-star.svg';

import Stars from './Stars';

import './style.css';

const ReviewTrustPilot = () => {
  const reviews = [
    {
      id: 0,
      content:
        'Dante AI is the perfect solution for our Agency. It’s fast and versatile, but the most important part is direct and fast support.',
      rating: 5,
    },
    {
      id: 1,
      content:
        'I have been using Dante for a while now and although a novice in the exciting AI world, I have found the simplicity of use to be such an innovation in my journey.',
      rating: 5,
    },
    {
      id: 2,
      content:
        'I’ve been costumer of Dante AI for over a year now. Stunned with the support and the innovation that they offer! Highly recommend!',
      rating: 5,
    },
    {
      id: 3,
      content:
        'It’s so reliable and efficient that it now takes care of 95% of our customer service.',
      rating: 5,
    },
    {
      id: 4,
      content:
        'I can’t recommend Dante enough. Even if you already use a no-code solution, you can sign up for a trial and compare in parallel.',
      rating: 5,
    },
    {
      id: 5,
      content:
        'I run a small business and integrated a Dante AI chatbot to my website about 6 months ago - it has been a huge success for us.',
      rating: 5,
    },
  ];

  return (
    <div className="rounded-size1 flex items-center justify-center bg-black text-[#fff]/75 h-full p-size8 gap-size4">
      <div className="flex flex-col gap-size4 h-full items-center overflow-y-auto no-scrollbar min-h-0 justify-start tall:justify-center">
        <header>
          <h1 className="text-2xl xl:text-3xl text-center">
            Reimagine your business
            <br />
            with <strong>24/7</strong> active AI agents
          </h1>
        </header>
        <div className="flex flex-col gap-size4 py-size3 items-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-size4">
            {reviews.map((review) => (
              <div
                key={review.id}
                className="flex flex-col gap-size3 p-size3 border border-[#ffffff]/10 rounded-size1 backdrop-blur-lg review text-center items-center"
              >
                <p>“{review.content}”</p>
                <Stars rating={review.rating} />
              </div>
            ))}
          </div>
          <footer className="flex gap-size4 items-center">
            <div className="trust-by">
              <img
                src={companies}
                alt="Trustpilot star"
                width={300}
                height={65}
              />
            </div>
            <div className="trustpilot flex flex-col gap-size2 border-l px-size1 py-size0">
              <p className="text-lg">
                We are rated <strong>4.7</strong> out of 5
              </p>
              <img src={trustpilotStar} alt="Trustpilot star" width={141} />
              <img src={trustpilotLogo} alt="Trustpilot star" width={82} />
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default ReviewTrustPilot;
