import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { getDynamicTeamImage } from '@/helpers/getDynamicImage';
import useIsAboveBreakpoint from '@/helpers/useIsAboveBreakpoint';
import * as teamManagementService from '@/services/teamManagement.service';
import useLayoutStore from '@/stores/layout/layoutStore';
import useTeamManagementStore from '@/stores/teamManagement/teamManagementStore';
import { useUserStore } from '@/stores/user/userStore';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';

import DModalCreateNew from '../DModalCreateNew';
import DNotificationHeader from '../DNotificationHeader';
import AiVoiceIcon from '../Global/Icons/AiVoiceIcon';
import DButtonIcon from '../Global/DButtonIcon';
import DFullLogo from '../Global/DLogo/DFullLogo';
import DNavLink from '../Global/DNavLink';
import ActivityIcon from '../Global/Icons/ActivityIcon';
import AddIcon from '../Global/Icons/AddIcon';
import AiAvatarIcon from '../Global/Icons/AiAvatarIcon';
import AiChatbotIcon from '../Global/Icons/AiChatbotIcon';
import BookIcon from '../Global/Icons/BookIcon';
import ChevronDownIcon from '../Global/Icons/ChevronDownIcon';
import ClockIcon from '../Global/Icons/ClockIcon';
import CloseIcon from '../Global/Icons/CloseIcon';
import CloseNavIcon from '../Global/Icons/CloseNavIcon';
import ExpandIcon from '../Global/Icons/ExpandIcon';
import GraphIcon from '../Global/Icons/GraphIcon';
import LeaveTeamIcon from '../Global/Icons/LeaveTeamIcon';
import LiveAgentIcon from '../Global/Icons/LiveAgentIcon';
import NotificationIcon from '../Global/Icons/NotificationIcon';
import OpenNavIcon from '../Global/Icons/OpenNavIcon';
import OptionsHorizontalIcon from '../Global/Icons/OptionsHorizontalIcon';
import HeaderAccountMenu from '../HeaderAccountMenu';
import './index.css';
import PhoneIcon from '../Global/Icons/PhoneIcon';
import DModalBetaVoice from '../DModalBetaVoice';
import DTransition from '../Global/DTransition';
import DCircularChart from '../Global/DCircularChart';
import DModalBetaAvatar from '../DModalBetaAvatar';
import featureCheck, { checkTeamManagementPermission } from '@/helpers/tier/featureCheck';
import useToast from '@/hooks/useToast';
import AvatarIcon from '../Global/Icons/AvatarIcon';
import * as userService from '@/services/user.service';
import clsx from 'clsx';
import DTooltip from '../Global/DTooltip';
import InfoIcon from '../Global/Icons/InfoIcon';
import DButton from '../Global/DButton';
import ZapIcon from '../Global/Icons/ZapIcon';

const MainNav = ({ hideCollapsedButton = false, closeMobileNav }) => {
  // Store references
  const sidebarOpen = useLayoutStore((state) => state.sidebarOpen);
  const setSidebarOpen = useLayoutStore((state) => state.setSidebarOpen);
  const setUser = useUserStore((state) => state.setUser);
  const userData = useUserStore((state) => state.user);

  const {
    owner,
    userMode,
    selectedTeam,
    setSelectedTeam,
    setUserMode,
    setTeamName,
    teamName,
    setTeams,
    setOwner,
    teams,
  } = useTeamManagementStore((state) => state);

  const location = useLocation();
  const navigate = useNavigate();
  const { addWarningToast } = useToast();
  const isAboveSm = useIsAboveBreakpoint('sm');

  const [isCollapsed, setIsCollapsed] = useState(sidebarOpen || true);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [teamDropdownOpened, setTeamDropdownOpened] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  const [openModalCreateNew, setOpenModalCreateNew] = useState(false);
  const [openAIVoiceModal, setOpenAIVoiceModal] = useState(false);
  const [openAiAvatarModal, setOpenAiAvatarModal] = useState(false);

  const TRIAL_DAYS = userData?.new_design_trial && userData?.has_created_chatbot ? 14 : 7;

  // Nav links
  const initialNavLinks = [
    {
      label: 'Create New',
      icon: <AddIcon />,
      iconPlacement: 'pre',
      href: '',
      active: openModalCreateNew,
      activeFunction: () => {
        return openModalCreateNew;
      },
      onClick: () => {
        setOpenModalCreateNew(true);
      },
    },
    {
      label: 'AI Chatbots',
      icon: <AiChatbotIcon />,
      iconPlacement: 'pre',
      active:
        location.pathname === '/' || location.pathname.startsWith('/chatbot'),
      activeFunction: () => {
        return (
          location.pathname === '/' || location.pathname.startsWith('/chatbot')
        );
      },
      href: '/',
      onClick: () => {
        navigate('/');
        closeMobileNav && closeMobileNav();
      },
      submenu: [
        {
          label: 'Human Handover',
          icon: <LiveAgentIcon />,
          iconPlacement: 'pre',
          href: '/human-handover',
          active: location.pathname.startsWith('/human-handover'),
          activeFunction: () => {
            return location.pathname.startsWith('/human-handover');
          },
          onClick: (e) => {
            if (!featureCheck('live_agent')) {
              addWarningToast({
                message:
                  'Human Handover is available on higher tiers. Please upgrade to access it',
              });
              return;
            }
            e.preventDefault();
            navigate('/human-handover');
            closeMobileNav && closeMobileNav();
          },
          showIfNoTeam: true,
        },
      ],
      submenuOpen: false,
      submenuFunction: () => {
        return (
          location.pathname.startsWith('/chatbot') ||
          location.pathname.startsWith('/human-handover')
        );
      },
    },
    // {
    //   label: 'AI Digital Personas',
    //   icon: <AiAvatarIcon />,
    //   iconPlacement: 'pre',
    //   href: '',
    //   active: openAiAvatarModal,
    //   activeFunction: () => {
    //     return openAiAvatarModal;
    //   },
    //   onClick: () => {
    //     setOpenAiAvatarModal(true);
    //     closeMobileNav && closeMobileNav();
    //   },
    //   showIfNoTeam: true,
    // },
    {
      label: 'AI Voice Agents',
      icon: <AiVoiceIcon />,
      iconPlacement: 'pre',
      href: '/voice',
      active: openAIVoiceModal,
      activeFunction: () => {
        return location.pathname.startsWith('/voice');
      },
      onClick: () => {
        console.log('from main nav', userData)
        if (userData?.credits_key?.ai_voice_minutes_available != null) {
          navigate('/voice'); 
        } else {
          setOpenAIVoiceModal(true);
        }
        closeMobileNav && closeMobileNav();
      },
      submenu: userData?.credits_key?.ai_voice_minutes_available != null ? [
        {
          label: 'Phone Numbers',
          icon: <PhoneIcon />,
          iconPlacement: 'pre',
          href: '/phone-numbers',
          active: location.pathname.startsWith('/phone-numbers'),
          activeFunction: () => {
            return location.pathname.startsWith('/phone-numbers');
          },
          onClick: (e) => {
            e.preventDefault();
            navigate('/phone-numbers');
            closeMobileNav && closeMobileNav();
          },
        },
      ] : null,
      submenuOpen: false,
      submenuFunction: () => {
        return location.pathname.startsWith('/phone-numbers');
      },
      showIfNoTeam: true,
    },
  ];
  const [navLinks, setNavLinks] = useState(initialNavLinks);

  const fetchUserProfile = async (signal) => {
    try {
      const response = await userService.getUserProfile({ signal });
      if (response.status === 200) {
        setUser(response.data);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.log('error', error);
      }
    }
  };

  // Effects
  useEffect(() => {
    if (window.innerWidth < 768) {
      setSidebarOpen(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    getTeams();
  }, [teamName]);

  useEffect(() => {
    setIsCollapsed(!sidebarOpen);
  }, [sidebarOpen]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchUserProfile(signal);
    return () => {
      controller.abort();
    };
  }, [selectedTeam]);

  const getTeams = async () => {
    const response = await teamManagementService.getTeams();
    setOwner(
      response.data.results.find((team) => team.owner_id === userData.id)
    );
    setTeams(response.data.results);
  };

  // Toggle the entire sidebar’s collapse/expand
  const handleToggleNav = () => {
    setIsCollapsed(!isCollapsed);
    setSidebarOpen(!sidebarOpen);
  };

  // Submenu toggling
  const toggleSubmenu = (label) => {
    setNavLinks((prev) =>
      prev.map((item) =>
        item.label === label
          ? { ...item, submenuOpen: !item.submenuOpen }
          : item
      )
    );
  };

  // Team management
  const handleTeamManagementClick = async () => {
    setTeamDropdownOpened(false);
    try {
      if (teams.length === 0 || !teams.some(team => team.owner_id === userData?.id)) {
        const response = await teamManagementService.createTeam(
          `${userData.full_name}'s team`
        );
        if (response.status === 200) {
          setSelectedTeam(response.data);
          getTeams();
          fetchUserProfile();
          navigate(`/team-management/${response.data.id}`);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleTeamClick = async (e, team) => {
    e.stopPropagation();
    setUserMode(false);
    setSelectedTeam(team);
    setTeamName(team.name);
    navigate(`/team-management/${team.id}`);
  };

  const handleLeaveTeam = async (id) => {
    try {
      const response = await teamManagementService.leaveTeam(id);
      if (response.status === 200) {
        setSelectedTeam(null);
        setUserMode(true);
        setTeamDropdownOpened(false);
        navigate('/');
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const updateNavLinks = () => {
    return initialNavLinks
      .filter((navLink) => {
        if (navLink.showIfNoTeam && selectedTeam) {
          return false;
        }
        if (navLink.label === 'Create New' && selectedTeam) {
          return checkTeamManagementPermission('create_chatbot');
        }
        return true;
      })
      .map((navLink) => {
        const updatedLink = {
          ...navLink,
          active: navLink.activeFunction(),
        };
  
        if (navLink.submenu) {
          updatedLink.submenu = navLink.submenu
            .filter((subNav) => {
              if (subNav.showIfNoTeam && selectedTeam) {
                return false;
              }
              return true;
            })
            .map((subNav) => ({
              ...subNav,
              active: subNav.activeFunction ? subNav.activeFunction() : false,
            }));
  
          updatedLink.submenuOpen =
            typeof navLink.submenuFunction === 'function'
              ? navLink.submenuFunction() && !selectedTeam
              : false;
        }
  
        return updatedLink;
      });
  };
  

  // useEffect for updating navLinks based on state changes
  useEffect(() => {
    setNavLinks(updateNavLinks());
  }, [
    location.pathname,
    openModalCreateNew,
    openAIVoiceModal,
    openAiAvatarModal,
    userData
  ]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
    <aside className="h-full flex flex-col">
      <div
        className={clsx(
          'main_nav_wrapper bg-white px-size1 py-size3 xl:p-size3',
          'overflow-hidden',
          hasMounted && 'transition-all duration-300',
          isCollapsed ? 'w-20' : 'w-[220px]'
        )}
      >
        <div
          className={`flex flex-col w-full ${
            (dropdownOpened || teamDropdownOpened) && 'dropdown_opened'
          }`}
        >
          {/* Header (logo + collapse button + maybe user menu on mobile) */}
          <div
            className={`flex items-center ${
              isCollapsed ? 'justify-between' : 'justify-between'
            } gap-1 text-base w-full`}
          >
            {/* Logo (hide or shrink when collapsed) */}
            {!isCollapsed && (
              <button
                className="dbutton overflow-hidden transition-all"
                onClick={() => navigate('/')}
              >
                <DFullLogo />
              </button>
            )}

            {/* Collapse button */}
            {!hideCollapsedButton && (
              <div
                className={clsx(
                  'dbutton transition-all flex justify-center items-center',
                  {
                    'w-full': isCollapsed,
                  }
                )}
              >
                <button onClick={handleToggleNav}>
                  {isCollapsed ? <OpenNavIcon /> : <CloseNavIcon />}
                </button>
              </div>
            )}

            {/* Mobile only stuff */}
            {!isAboveSm && (
              <div className="flex gap-size2 items-center justify-end">
                <DNotificationHeader />
                <DButtonIcon size="lg">
                  <BookIcon className="w-6 h-6" />
                </DButtonIcon>
                <HeaderAccountMenu closeMobileNav={closeMobileNav}/>
                <DButtonIcon
                  size="lg"
                  onClick={closeMobileNav}
                  className="border border-grey-5 rounded-size0"
                >
                  <CloseIcon className="w-6 h-6" />
                </DButtonIcon>
              </div>
            )}
          </div>

          {/* Divider */}
          <div className="border border-grey-5 w-full my-size4"></div>

          {/* NAVIGATION LINKS */}
          <div className="main_nav-nav_links w-full flex flex-col gap-size2">
            {navLinks.map((navLink, index) => (
              <div key={navLink.label} className="relative">
                <DNavLink
                  label={isCollapsed ? '' : navLink.label}
                  icon={navLink.icon}
                  iconPlacement={navLink.iconPlacement}
                  active={navLink.active}
                  collapsed={isCollapsed}
                  onClick={() => {
                    if (navLink?.submenu && navLink.submenu.length > 0) {
                      toggleSubmenu(navLink.label);
                      navLink.onClick?.();
                    } else {
                      navLink.onClick?.();
                    }
                  }}
                  className={`flex items-center w-full ${
                    navLink?.submenu && navLink.submenu.length > 0
                      ? 'cursor-pointer'
                      : ''
                  }`}
                >
                  {!isCollapsed && navLink?.submenu?.length > 0 && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleSubmenu(navLink.label);
                      }}
                    >
                      <ChevronDownIcon
                        className={`w-3 h-3 ${
                          navLink.submenuOpen ? 'rotate-180' : ''
                        }`}
                      />
                    </span>
                  )}
                </DNavLink>

                {/* Submenu transition */}
                <DTransition
                  show={navLink.submenu && navLink.submenuOpen}
                  className="mt-size2"
                  type="collapse"
                >
                  {navLink?.submenu?.map((subNav) => (
                    <DNavLink
                      key={subNav.label}
                      label={isCollapsed ? '' : subNav.label}
                      icon={subNav.icon}
                      iconPlacement={subNav.iconPlacement}
                      active={subNav.active}
                      onClick={subNav.onClick}
                    />
                  ))}
                </DTransition>

                {/* Example divider after certain item */}
                {index === 4 && (
                  <div className="h-px w-full bg-gray-300 mt-2"></div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/*
          USAGE BARS AND TRIAL DAYS
          We put them above the team management (still near bottom).
        */}
        <div className="flex flex-col w-full gap-size3 mt-size4">
          {/* Account usage section */}
          <div
            className={`flex flex-col w-full gap-size2 relative ${
              teamDropdownOpened && 'dropdown_opened'
            }`}
          >
            {/*
              If collapsed, we might just show DCircularChart
              Otherwise, show full usage bars
            */}
            {isCollapsed ? (
              <div className="flex justify-center items-center">
                <DCircularChart
                  value={userData?.credits_key?.percentage}
                  size="sm"
                />
              </div>
            ) : (
              <div className="flex flex-col gap-size2">
              {userData && userData?.tier_on_trial && userData?.new_design_trial && !selectedTeam && (
                  <div className="flex flex-col gap-size1">
                    <div className="flex w-full items-center justify-between gap-size1">
                      <p className="text-sm tracking-tight text-grey-50">
                        <span className="text-black">
                          {userData?.new_design_trial_days_left}
                        </span>{' '}
                        trial days left
                      </p>
                      {/* {userData?.tier_on_trial && !userData?.has_created_chatbot && <DTooltip
                        content={<div  className='flex flex-col gap-size1 p-size1'>
                          <p>Unlock 7 additional free trial <br /> days by creating an AI Chatbot</p>
                          <DButton variant='contained' fullWidth onClick={() => navigate('/chatbot/create')}>
                            Create AI Chatbot
                          </DButton>
                        </div>}
                      >
                        <InfoIcon className="w-4 h-4 text-grey-50" />
                      </DTooltip>} */}
                    </div>
                    <DButton
                      variant="green"
                      className="!gap-size0"
                      onClick={() => navigate('/plans')}
                      fullWidth
                    >
                      <ZapIcon />
                      <span className="mr-size0">Add plan</span>
                    </DButton>
                    {/* <div className="flex space-x-1">
                      {[...Array(TRIAL_DAYS)].map((_, index) => {
                        const isFilled = (Math.min(
                          (userData?.new_design_trial_days_left ),
                          TRIAL_DAYS
                        )) > index;
                        return (
                          <div
                            key={index}
                            className={`h-1 w-full rounded ${
                              isFilled ? 'bg-negative-100' : 'bg-negative-10'
                            }`}
                          ></div>
                        );
                      })}
                    </div> */}
                  </div>
                )}
                {/* Minutes left */}
                {userData?.credits_key?.ai_voice_minutes_used != null &&
                  userData?.credits_key?.ai_voice_minutes_available != null && (
                    <div className="flex flex-col gap-size1">
                      <div className="flex w-full items-center justify-between gap-size1">
                        <p className="text-sm tracking-tight text-grey-50">
                          <span className="text-black">
                            {Math.round(
                              userData.credits_key.ai_voice_minutes_available -
                                userData.credits_key.ai_voice_minutes_used
                            )}
                          </span>{' '}
                          AI Voice Agent Beta minutes. <a className="text-black underline font-medium" href="https://www.dante-ai.com/guides/how-to-create-an-ai-voice-agent" target="_blank" rel="noopener noreferrer">Learn more</a>
                        </p>
                      </div>
                      <div className="w-full bg-green-10 h-0.5">
                        <div
                          className="bg-green-400 h-0.5"
                          style={{
                            width: `${Math.ceil(
                              userData.credits_key.percentage
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                {/* Credits left */}
                {userData?.credits_key?.credits_available != null &&
                  userData?.credits_key?.credits_used != null && (
                    <div className="flex flex-col gap-size1">
                      <div className="flex w-full items-center justify-between gap-size1">
                        <p className="text-sm tracking-tight text-grey-50">
                          <span className="text-black">
                            {Math.round(
                              userData.credits_key.credits_available -
                                userData.credits_key.credits_used
                            )}
                          </span>{' '}
                          credits
                        </p>
                      </div>
                      <div className="w-full bg-green-10 h-0.5">
                        <div
                          className="bg-green-400 h-0.5"
                          style={{
                            width: `${Math.ceil(
                              userData.credits_key.percentage
                            )}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                {userData && userData?.tier_on_trial && !userData?.new_design_trial && !selectedTeam && userData?.trial_key?.trial_days_left > 0 && (
                  <div className="flex flex-col gap-size1">
                    <div className="flex w-full items-center justify-between gap-size1">
                      <p className="text-sm tracking-tight text-grey-50">
                        <span className="text-black">
                          {userData?.trial_key?.trial_days_left}
                        </span>{' '}
                        trial days left
                      </p>
                    </div>
                    <div className="flex space-x-1">
                      {[...Array(14)].map((_, index) => {
                        const isFilled = (Math.min(
                          (userData?.trial_key?.trial_days_left ),
                          14
                        )) > index;
                        return (
                          <div
                            key={index}
                            className={`h-1 w-full rounded ${
                              isFilled ? 'bg-negative-100' : 'bg-negative-10'
                            }`}
                          ></div>
                        );
                      })}
                    </div>
                  </div>
                )}

                {/* AI Chatbots used */}
                {userData && userData?.used_ai_chatbots >= 0 && !selectedTeam && (
                  <div className="flex flex-col gap-size1">
                    <div className="flex w-full items-center justify-between gap-size1">
                      <p className="text-sm tracking-tight text-grey-50">
                        <span className="text-black">
                          {userData?.used_ai_chatbots}
                        </span>
                        /{userData?.total_ai_chatbots} AI chatbots
                      </p>
                      {userData?.total_ai_chatbots < userData?.used_ai_chatbots && <DTooltip content="Your chatbots exceed the trial limit and will be deleted.">
                        <InfoIcon className="size-4 text-grey-50"/>
                      </DTooltip>}
                    </div>
                    <div className="w-full bg-purple-10 h-0.5">
                      <div
                        className="bg-purple-300 h-0.5 max-w-[100%]"
                        style={{
                          width: `${
                            (userData?.used_ai_chatbots /
                              userData?.total_ai_chatbots) *
                            100
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Divider */}
          <div className="border border-grey-5 w-full"></div>

          {/* TEAM MANAGEMENT - pinned at bottom */}
          <div
            className={`main_nav-team_management relative w-full justify-center items-center flex ${
              dropdownOpened && 'dropdown_opened'
            }`}
          >
            <Menu>
              {({ open }) => {
                if (teamDropdownOpened !== open) {
                  setTeamDropdownOpened(open);
                }
                return (
                  <>
                    <MenuButton
                      className={`flex items-center w-full rounded-size3 hover:bg-grey-2 ${
                        !isCollapsed ? '' : 'justify-center'
                      }`}
                    >
                      <div
                        className={`flex items-center gap-size1 text-base font-regular ${
                          isCollapsed ? 'm-auto' : ''
                        }`}
                      >
                        <img
                          src={
                            selectedTeam?.team_icon ??
                            'https://dante-ai-files.s3.eu-west-1.amazonaws.com/profile-images/team/team-01-small.png'
                          }
                          alt="Team Management Avatar"
                          className="size-10"
                        />
                        {!isCollapsed && (
                          <span className="text-sm truncate w-[150px] block">
                            {selectedTeam
                              ? `${selectedTeam?.name}`
                              : `Create a team`}
                          </span>
                        )}
                      </div>
                      {!isCollapsed && <ExpandIcon className="text-grey-50" />}
                    </MenuButton>

                    <MenuItems
                      anchor={isCollapsed ? 'right end' : 'top'}
                      className="border border-grey-5 bg-white rounded-size2 w-[210px] shadow-md p-size1 flex flex-col gap-size1"
                    >
                    {!userMode &&  
                      <MenuItem           
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedTeam(null);
                            setUserMode(true);
                            setTeamDropdownOpened(false);
                            navigate('/');
                          }}
                          className='cursor-pointer'
                        >
                          <div className="flex items-center gap-size1 cursor-pointer">
                            <AvatarIcon className="size-5" />
                            <span className="text-sm font-regular">
                              Switch to user mode
                            </span>
                          </div>
                        </MenuItem>}
                      {teams.map((team) => (
                        <MenuItem
                          key={team.id}
                          onClick={(e) => handleTeamClick(e, team)}
                        >
                          <a
                            className="flex justify-between gap-size0 items-center hover:bg-grey-2"
                            href="#!"
                          >
                            <div className="flex items-center gap-size1 text-sm font-regular justify-between">
                              <img
                                src={
                                  team?.team_icon ??
                                  'https://dante-ai-files.s3.eu-west-1.amazonaws.com/profile-images/team/team-02-small.png'
                                }
                                alt="Team Management Avatar"
                                className="w-8 h-8"
                              />
                              <span
                                className={`truncate ${
                                  owner?.owner_id === team?.owner_id
                                    ? 'max-w-[120px]'
                                    : 'max-w-[85px]'
                                }`}
                              >
                                {team.name}
                              </span>
                            </div>
                            <span className="flex items-center justify-center h-4 text-black bg-grey-5 py-o.5 px-size0 rounded-size0 text-[8px] font-bold">
                              {owner?.owner_id === team.owner_id
                                ? 'Owner'
                                : 'Guest'}
                            </span>
                            {(owner?.owner_id !== team.owner_id) && (
                              <Menu>
                                {({ open, close }) => (
                                  <>
                                    <MenuButton
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        if (open) close();
                                      }}
                                      className="h-[50px]"
                                    >
                                      <OptionsHorizontalIcon />
                                    </MenuButton>
                                    <MenuItems
                                      anchor="bottom end"
                                      className="border border-grey-5 bg-white w-[190px] rounded-size0 shadow-md p-size1 flex flex-col gap-size1"
                                    >
                                      {owner?.owner_id !== team?.owner_id && (
                                        <MenuItem
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleLeaveTeam(team.id);
                                          }}
                                        >
                                          <div className="flex items-center gap-size1">
                                            <LeaveTeamIcon className="text-negative-200" />
                                            <span className="text-sm font-regular cursor-pointer text-negative-200">
                                              Leave Team
                                            </span>
                                          </div>
                                        </MenuItem>
                                      )}
                                    </MenuItems>
                                  </>
                                )}
                              </Menu>
                            )}
                          </a>
                        </MenuItem>
                      ))}
                      {(teams.length === 0 || !teams.some(team => team.owner_id === userData?.id)) && (
                        <span
                          className="text-sm font-regular cursor-pointer"
                          onClick={handleTeamManagementClick}
                        >
                          Create team
                        </span>
                      )}
                    </MenuItems>
                  </>
                );
              }}
            </Menu>
          </div>
        </div>
      </div>

      {/* Modals */}
      <DModalCreateNew
        open={openModalCreateNew}
        onClose={() => {
          setOpenModalCreateNew(false);
          closeMobileNav && closeMobileNav();
        }}
        handleOpenModalBetaVoice={() => {
          if (userData?.credits_key?.ai_voice_minutes_available != null) {
            navigate('/voice');
          } else {
            setOpenAIVoiceModal(true);
          }
        }}
        handleOpenModalBetaAvatar={() => {
          setOpenAiAvatarModal(true);
        }}
      />
      <DModalBetaVoice
        open={openAIVoiceModal}
        onClose={() => {
          setOpenAIVoiceModal(false);
        }}
      />
      <DModalBetaAvatar
        open={openAiAvatarModal}
        onClose={() => {
          setOpenAiAvatarModal(false);
        }}
      />
    </aside>
  );
};

export default MainNav;
