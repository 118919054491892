import DToastContainer from '@/components/DToast/DToastContainer';
import DFullLogo from '@/components/Global/DLogo/DFullLogo';
import useIsAboveBreakpoint from '@/helpers/useIsAboveBreakpoint';
import { Transition, TransitionChild } from '@headlessui/react';
import { useEffect, useState } from 'react';

const LayoutPublic = ({ leftSide, rightSide, footerLeftSide }) => {
  const isAboveMd = useIsAboveBreakpoint('md');
  const [isEnter, setIsEnter] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsEnter(true);
    }, 200);
  }, []);

  return (
    <div className="flex flex-col d-h-screen w-full">
      <div className="w-full flex flex-col items-end px-size2">
        <DToastContainer />
      </div>
      <Transition show={isEnter} appear>
        <div className="h-full flex flex-col md:flex-row p-size3">
          <TransitionChild>
          <div
              className={`transition duration-150 flex flex-col h-full md:px-size8 py-size3 ${
                !rightSide ? 'md:w-full' : 'md:w-1/2'
              } data-[closed]:opacity-0 data-[closed]:-translate-x-full`}
            >
              <header className="flex-shrink-0">
                <DFullLogo />
              </header>
              {/* Middle content takes the remaining space and scrolls if necessary */}
              <div className="flex-1">
                <div className="flex flex-col justify-start relative h-full overflow-y-auto min-h-0">{leftSide}</div>
              </div>
              {footerLeftSide && (
                <footer className="flex-shrink-0">
                  {footerLeftSide}
                </footer>
              )}
            </div>
          </TransitionChild>
          {isAboveMd && rightSide && (
            <TransitionChild>
              <div className="transition duration-150 h-full flex-col w-[50%] justify-center data-[closed]:opacity-0 data-[closed]:translate-x-full hidden md:flex">
                {rightSide}
              </div>
            </TransitionChild>
          )}
        </div>
      </Transition>
    </div>
  );
};

export default LayoutPublic;
