import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import DAlert from '@/components/Global/DAlert';
import DButton from '@/components/Global/DButton';
import DInput from '@/components/Global/DInput/DInput';
import DInputBlock from '@/components/Global/DInput/DInputBlock';
import ReviewTrustPilot from '@/components/Onboarding/ReviewTrustPilot';
import LayoutPublic from '@/layouts/LayoutPublic';

import './login.css';
import DTransition from '@/components/Global/DTransition';
import DCheckbox from '@/components/Global/DCheckbox';
import { resetPassword } from '@/services/user.service';
import { useUserStore } from '@/stores/user/userStore';

const ChangePasswordPage = () => {
  const navigate = useNavigate();

  const [form, setForm] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    contactConsent: true,
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const saveAuthDetail = useUserStore((state) => state.saveAuthDetail);

  /**
   * Handles input field changes and updates the form state.
   */
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  /**
   * Handles the form submission for changing the password.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // Check if the new password matches the confirmation
    if (form.newPassword !== form.confirmPassword) {
      setError("New passwords do not match");
      return;
    }

    try {
      setLoading(true);
      const response = await resetPassword({
        old_password:  form.oldPassword,
        new_password:  form.newPassword,
        contact_consent: form.contactConsent
      });

      if (response.status === 200) {
        saveAuthDetail({
            access_token: response.data.access_token,
        });
        setSuccess(true);
        navigate('/');
      }
    } catch (error) {
      console.error(error);
      setError(error.message || 'An error occurred while changing your password');
    } finally {
      setLoading(false);
    }
  };

  const handleRadioButtonChange = (checked) => {
    setForm({ ...form, contactConsent: checked });
  };

  return (
    <LayoutPublic
      leftSide={
        <div className="flex flex-col gap-size1">
          {error && <DAlert type="error">{error}</DAlert>}
          {success && <DAlert type="success">Your password has been changed successfully.</DAlert>}

          <DInputBlock label="Old Password">
            <DInput
              type="password"
              name="oldPassword"
              value={form.oldPassword}
              onChange={handleChange}
            />
          </DInputBlock>
          <DInputBlock label="New Password">
            <DInput
              type="password"
              name="newPassword"
              value={form.newPassword}
              onChange={handleChange}
            />
          </DInputBlock>
          <DInputBlock label="Confirm New Password">
            <DInput
              type="password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handleChange}
            />
          </DInputBlock>
          <DCheckbox
            label="Send me additional free support and feature updates"
            onChange={handleRadioButtonChange}
            checked={form.contactConsent}
            name="contact_consent"
          />
          <DButton onClick={handleSubmit} fullWidth variant="dark" disabled={loading}>
            {loading ? 'Changing...' : 'Change Password'}
          </DButton>
        </div>
      }
      footerLeftSide={
        <p>
          Want to use same password? <Link to="/log-in">Log in</Link>
        </p>
      }
      rightSide={<ReviewTrustPilot />}
    />
  );
};

export default ChangePasswordPage;