import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import * as amplitude from '@amplitude/analytics-browser';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};

const params = {};
const queryString = window.location.search.substring(1);
const pairs = queryString.split('&');

pairs.forEach((pair) => {
  const [key, value] = pair.split('=');
  if (key) {
    params[decodeURIComponent(key)] = decodeURIComponent(value || '');
  }
});


amplitude.init(import.meta.env.VITE_APP_AMPLITUDE_API_KEY, {
  deviceId: params.deviceId || undefined,
});


root.render(
  // <React.StrictMode>
  <GoogleOAuthProvider clientId={import.meta.env.VITE_APP_GOOGLE_CLIENT_ID}>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </GoogleOAuthProvider>
  // </React.StrictMode>
);
