// src/Routers.jsx

import React, { Suspense, lazy } from 'react';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';

// Store hooks
import useLayoutStore from '@/stores/layout/layoutStore';

// Layouts
import LayoutShell from '@/layouts/LayoutShell';
import LayoutDemo from '@/layouts/LayoutDemo';
// Route wrappers
import Entrypoint from './Entrypoint';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// Global loading & error
import DLoading from '@/components/DLoading';
import ErrorPage from '@/pages/Error';
import ChangePasswordPage from '@/pages/Auth/ChangePassword';

// ============ Lazy-Loaded Pages ============

// Auth
const LoginPage = lazy(() => import('@/pages/Auth/Login'));
const ForgotPasswordPage = lazy(() => import('@/pages/Auth/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('@/pages/Auth/ResetPassword'));
const SignUp = lazy(() => import('@/pages/SignUp'));
const AcceptTeamInvite = lazy(() => import('@/pages/TeamManagement/AcceptTeamInvite'));

// Home & Misc
const Home = lazy(() => import('@/pages/Home'));
const Onboarding = lazy(() => import('@/pages/Onboarding'));
const Upgrade = lazy(() => import('@/pages/Upgrade'));
const Success = lazy(() => import('@/pages/Stripe/Success'));
const Cancel = lazy(() => import('@/pages/Stripe/Cancel'));
const Subscribe = lazy(() => import('@/pages/Subscribe'));
const Plans = lazy(() => import('@/pages/Plans'));
const Membership = lazy(() => import('@/pages/Membership'));
const MembershipPage = lazy(() => import('@/pages/Membership'));
const Branding = lazy(() => import('@/pages/Branding'));
const Profile = lazy(() => import('@/pages/Profile'));

// Human Handover
const HumanHandover = lazy(() => import('@/pages/HumanHandover'));

// Chatbot
const CreateChatbot = lazy(() => import('@/pages/CreateChatbot'));
const ChatbotDetails = lazy(() => import('@/pages/ChatbotDetails'));
const ChatbotDetailsOutlet = lazy(() => import('@/pages/ChatbotDetails/ChatbotDetailsOutlet'));
const ChatbotTabs = lazy(() => import('@/pages/ChatbotDetails/ChatbotTabs'));
const ChatbotInsights = lazy(() => import('@/pages/ChatbotDetails/ChatbotInsights'));
const ChatRecordDeatil = lazy(
  () => import('@/pages/ChatbotDetails/ChatRecordDeatil/ChatRecordDeatil')
);
const ChatbotIntegrations = lazy(() => import('@/pages/ChatbotDetails/ChatbotIntegrations'));
const WhatsappIntegration = lazy(
  () => import('@/pages/ChatbotDetails/Integrations/WhatsappIntegration')
);
const IntercomIntegration = lazy(
  () => import('@/pages/ChatbotDetails/Integrations/IntercomIntegration')
);
const ChatbotSafety = lazy(() => import('@/pages/ChatbotDetails/ChatbotSafety'));
const ChatbotKnowledge = lazy(() => import('@/pages/ChatbotDetails/ChatbotKnowledge'));
const ChatbotSetup = lazy(() => import('@/pages/ChatbotDetails/ChatbotSetup'));
const ChatbotStyling = lazy(() => import('@/pages/ChatbotDetails/ChatbotStyling'));
const ChatbotPowerups = lazy(() => import('@/pages/ChatbotDetails/ChatbotPowerups'));
const ChatbotPersonality = lazy(
  () => import('@/pages/ChatbotDetails/ChatbotPersonality')
);

// Team
const TeamManagement = lazy(() => import('@/pages/TeamManagement'));

// Voice
const Voice = lazy(() => import('@/pages/Voice'));
const CreateVoice = lazy(() => import('@/pages/CreateVoice'));
const PhoneNumbers = lazy(() => import('@/pages/Voice/PhoneNumbers'));
const EditVoice = lazy(() => import('@/pages/EditVoice'));
const VoiceConversations = lazy(() => import('@/pages/Voice/Conversations'));
const VoiceConversationDetails = lazy(() => import('@/pages/Voice/ConversationDetail'));
const AIVoiceDemo = lazy(() => import('@/pages/AIVoiceDemo'));
// Demo
const Demo = lazy(() => import('@/pages/Demo'));

// Admin
const AdminDashboard = lazy(() => import('@/pages/AdminDashboard'));



const router = createBrowserRouter([
  // ================= Public Routes =================
  {
    path: '/log-in',
    element: (
      <PublicRoute>
        <LoginPage />
      </PublicRoute>
    ),
  },
  {
    path: '/sign-up',
    element: (
      <PublicRoute>
        <SignUp />
      </PublicRoute>
    ),
  },
  {
    path: '/forgot-password',
    element: (
      <PublicRoute>
        <ForgotPasswordPage />
      </PublicRoute>
    ),
  },
  {
    path: '/reset-password',
    element: (
      <PublicRoute>
        <ResetPasswordPage />
      </PublicRoute>
    ),
  },
  {
    path: '/request-token',
    element: <PublicRoute>RequestToken</PublicRoute>,
  },
  {
    path: '/verify-email',
    element: <PublicRoute>VerifyEmail</PublicRoute>,
  },
  {
    path: '/demo',
    element: (
      <LayoutDemo>
        <Demo />
      </LayoutDemo>
    ),
  },
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Entrypoint />
      </PrivateRoute>
    ),
    errorElement: (
      <PrivateRoute>
        <ErrorPage />
      </PrivateRoute>
    ),
    children: [
      {
        index: true,
        element: (
          <LayoutShell>
            <Home />
          </LayoutShell>
        ),
      },
      {
        path: 'onboarding',
        element: (
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        ),
        children: [
          { path: 'create', element: <Onboarding /> },
          { path: 'upgrade', element: <Upgrade /> },
        ],
      },
      {
        path: 'chatbot',
        element: (
          <LayoutShell>
            <Outlet />
          </LayoutShell>
        ),
        children: [
          { path: 'create', element: <CreateChatbot /> },
          {
            path: ':id',
            element: <ChatbotDetailsOutlet />,
            children: [
              { index: true, element: <ChatbotDetails /> },
              { path: 'tabs', element: <ChatbotTabs /> },
              { path: 'insights', element: <ChatbotInsights /> },
              { path: 'insights/record/:recordId', element: <ChatRecordDeatil /> },
              { path: 'integrations', element: <ChatbotIntegrations /> },
              { path: 'integrations/whatsapp', element: <WhatsappIntegration /> },
              { path: 'integrations/intercom', element: <IntercomIntegration /> },
              { path: 'safety', element: <ChatbotSafety /> },
              { path: 'knowledge', element: <ChatbotKnowledge /> },
              { path: 'personality', element: <ChatbotPersonality /> },
              { path: 'setup', element: <ChatbotSetup /> },
              { path: 'styling', element: <ChatbotStyling /> },
              { path: 'powerups', element: <ChatbotPowerups /> },
            ],
          },
        ],
      },
      {
        path: 'profile',
        element: (
          <LayoutShell>
            <Profile />
          </LayoutShell>
        ),
      },
      {
        path: 'membership',
        element: (
          <LayoutShell>
            <Membership />
          </LayoutShell>
        ),
      },
      {
        path: 'membership-page',
        element: (
          <LayoutShell>
            <MembershipPage />
          </LayoutShell>
        ),
      },
      {
        path: 'team-management',
        element: (
          <LayoutShell>
            <TeamManagement />
          </LayoutShell>
        ),
        children: [
          { path: ':id', element: <TeamManagement /> },
        ],
      },
      {
        path: 'branding',
        element: (
          <LayoutShell>
            <Branding />
          </LayoutShell>
        ),
      },
      {
        path: 'voice',
        element: (
          <LayoutShell>
            <Outlet />
          </LayoutShell>
        ),
        children: [
          { index: true, element: <Voice /> },
          { path: 'create', element: <CreateVoice /> },
          { path: ':id/edit', element: <EditVoice /> },
          { path: ':id/conversations', element: <VoiceConversations />},
          { path: ':id/conversations/:conversationId', element: <VoiceConversationDetails />},
        ],
      },
      {
        path: 'phone-numbers',
        element: (
          <LayoutShell>
            <PhoneNumbers />
          </LayoutShell>
        ),
      },
      {
        path: 'human-handover',
        element: (
          <LayoutShell>
            <HumanHandover />
          </LayoutShell>
        ),
      },
      {
        path: 'subscribe',
        element: (
          <LayoutShell>
            <Subscribe />
          </LayoutShell>
        ),
      },
      {
        path: 'plans',
        element: (
          <LayoutShell>
            <Plans />
          </LayoutShell>
        ),
      },
      {
        path: 'admin/dashboard',
        element: (
          <LayoutShell>
            <AdminDashboard />
          </LayoutShell>
        ),
      },
      {
        path: 'accept-team-invite',
        element: <AcceptTeamInvite />,
      },
      {
        path: 'change-password',
        element: (
          <PrivateRoute>
            <Outlet />
          </PrivateRoute>
        ),
        children: [
          { path: '', element: <ChangePasswordPage /> },
        ],
      },
    ],
  },
  // Standalone AIVoiceDemo route - protected only by its own token system
  {
    path: '/ai-voice/demo',
    element: <AIVoiceDemo />
  },
  {
    path: '/stripe',
    element: (
      <PrivateRoute>
          <Outlet />
      </PrivateRoute>
    ),
    children: [
      { path: 'success', element: <Success /> },
      { path: 'cancel', element: <Cancel /> },
    ],
  },
]);

export default function Routers() {
  return (
    <Suspense fallback={<DLoading show={true} />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}
