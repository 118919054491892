import React, { useEffect, useState } from 'react';

import MoonIcon from '../Global/Icons/MoonIcon';
import SunIcon from '../Global/Icons/SunIcon';
import useLayoutStore from '@/stores/layout/layoutStore';

const DDarkModeSelector = React.forwardRef((_props, ref) => {
  const [dark, setDark] = useState(localStorage.getItem('theme') === 'dark');
  const setIsDarkMode = useLayoutStore((state) => state.setIsDarkMode);

  const darkModeHandler = () => {
    if (dark) {
      localStorage.setItem('theme', 'light');
      //dispatch an event to update the theme
      window.dispatchEvent(new Event('storage'));
      document.documentElement.classList.remove('dark');
    } else {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
      window.dispatchEvent(new Event('storage'));
    }
    setDark(!dark);
    setIsDarkMode(!dark);
  };

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (theme === 'dark') {
      setDark(true);
      setIsDarkMode(true);
    }
  }, []);

  return (
    <button
      ref={ref}
      className="dbutton flex items-center gap-size1 py-size2 px-size1 text-base font-regular tracking-tight hover:bg-grey-5 rounded-size0 w-full"
      onClick={() => darkModeHandler()}
    >
      {dark ? <SunIcon /> : <MoonIcon />}
      {dark ? <span>Lights on</span> : <span>Lights off</span>}
    </button>
  );
});

DDarkModeSelector.displayName = 'DDarkModeSelector';

export default DDarkModeSelector;
