import generateApiEndpoint from '@/helpers/generateApiEndpoint';
import http from './http';
import { DEFAULT_HEADERS } from './constants.service';

export const applyForBetaAccess = (reason) => {
    return http.post(generateApiEndpoint('ai-voices'), { reason }, {
        headers: DEFAULT_HEADERS
    });
}

export const getLoadingSounds = () => {
    return http.get(generateApiEndpoint('ai-voices/loading-sounds'));
}

export const getVoices = () => {
    return http.get(generateApiEndpoint('ai-voices'));
}

export const createVoice = (data) => {
    return http.post(generateApiEndpoint('ai-voices'), data, { headers: DEFAULT_HEADERS });
}

export const getVoice = (id) => {
    return http.get(generateApiEndpoint('ai-voices/' + id));
}

export const getTextToVoiceOptions = (provider) => {
    return http.get(generateApiEndpoint('ai-voices/text-to-voice/settings'), {
        params: { voice_provider: provider },
        headers: DEFAULT_HEADERS,
      });
}

export const getAllVoiceSettings = () => {
    return http.get(generateApiEndpoint('ai-voices/workflow-options/all'));
}

export const getAllVoiceSettingsFiltered = (options) => {
    return http.get(
      generateApiEndpoint('ai-voices/workflow-options/valid'),
      {
        params: options,
        headers: DEFAULT_HEADERS,
      }
    );
  };

export const updateVoice = (id, data) => {
    return http.patch(generateApiEndpoint('ai-voices/' + id), data, { headers: DEFAULT_HEADERS });
}

export const deleteVoice = (id) => {
    return http.delete(generateApiEndpoint('ai-voices/' + id), { headers: DEFAULT_HEADERS });
}

export const getVoiceConversations = (id) => {
    return http.get(generateApiEndpoint('ai-voices/conversations'), { params: { ai_voice_id: id } });
}

export const getVoiceConversationDetails = (id) => {
    return http.get(generateApiEndpoint('ai-voices/conversations/' + id));
}