import React, { useEffect } from 'react';

import useIsAboveBreakpoint from '@/helpers/useIsAboveBreakpoint';

import LayoutMediumScreen from '../LayoutMediumScreen';
import LayoutMobile from '../LayoutMobile';
import DModalWelcome from '@/components/Onboarding/DModalWelcome';
import useLayoutStore from '@/stores/layout/layoutStore';

/**
 * This layout serves as the base of the app.
 * It controls which basic layout is displayed according to the device's screen width.
 * The layouts managed here are those that include the main navigation, `MainNav`.
 */

const LayoutShell = ({ children }) => {
  const isAboveSm = useIsAboveBreakpoint('sm');
  const { setWelcomeModal, welcomeModal } = useLayoutStore();
  const title = useLayoutStore((state) => state.layoutTitle);
  const progressBar = useLayoutStore((state) => state.progressBar);

  if (isAboveSm) {
    return (
      <LayoutMediumScreen title={title} progressBar={progressBar}>
        {children}
        {/* disable this for now - James request {welcomeModal.show && <DModalWelcome />} */}
      </LayoutMediumScreen>
    );
  }
  return (
    <LayoutMobile title={title} progressBar={progressBar}>
      {children}
      {/* Disable welcome modal for now - James request {welcomeModal.show && <DModalWelcome />} */}
    </LayoutMobile>
  );
};

export default LayoutShell;
